
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: hsl(210, 36%, 96%);
  color: hsl(209, 61%, 16%);
  line-height: 1.5;
  font-size: 0.875rem;
}
h3 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.0;
  margin-bottom: 0.75rem;
  font-size: 2rem;
}
p {
  margin-bottom: 1.25rem;
  color: hsl(209, 34%, 30%);
}
@media screen and (min-width: 800px) {
  h2 {
    font-size: 2.5rem;
   }
    body {
    font-size: 1rem;
  }
  h2 {
    line-height: 1;
  }
}

/*
=============== 
Quiz
===============
*/

main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quiz {
  width: 90vw;
  max-width: 1170px;
  margin: 4rem auto;
  background: #fff;
  border-radius: 0.25rem;
  padding: 3rem;
}
.quiz-small {
  max-width: 500px;
}
.container h2 {
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.5;
  text-transform: none;
}
.answer-btn {
  display: block;
  width: 100%;
  margin: 0.75rem auto;
  background: hsl(205, 90%, 76%);
  border-radius: 0.25rem;
  border-color: transparent;
  color: #222;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 0;
  transition: all 0.3s linear;
}
.answered-btn {
  display: block;
  width: 60%;
  margin: 0.75rem auto;
  background: #ffb100;
  border-radius: 0.25rem;
  border-color: transparent;
  color: #222;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  padding: 0.5rem 0;
  transition: all 0.3s linear;
}
@media screen and (min-width: 576px) {
  .answer-btn {
    max-width: 60%;
  }
}
.answer-btn:hover {
  background:hsl(205, 78%, 60%);
  color: #fff;
}
.correct-answers {
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: right;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  color: hsl(125, 67%, 44%);
}
.next-question,
.close-btn,
.submit-btn {
  border-radius: 0.25rem;
  border-color: transparent;
  padding: 0.25rem 0.5rem;
  display: block;
  width: 35%;
  margin-left: auto;
  margin-top: 2rem;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  background: #ffb100;
  color: #222;
  transition: all 0.3s linear;
  cursor: pointer;
}
.next-question:hover {
  background: #805900;
  color: #ffb100;
}
/*
=============== 
Form
===============
*/

.setup-form h2 {
  margin-bottom: 2rem;
}
.form-control {
  margin-bottom: 2rem;
}
.form-control label {
  display: block;
  text-transform: capitalize;
  font-weight: 500;
  color: hsl(209, 34%, 30%);
  margin-bottom: 0.5rem;
}
.form-input {
  border: none;
  background: hsl(210, 36%, 96%);
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
}
.error {
  color: hsl(360, 67%, 44%);
  text-transform: capitalize;
}
.submit-btn {
  width: 100%;
  margin-top: 3rem;
}
/*
=============== 
Loading
===============
*/
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color:hsl(205, 78%, 60%);
  animation: spinner 0.6s linear infinite;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s linear;
  z-index: -1;
}
.isOpen {
  opacity: 1;
  z-index: 999;
}

.modal-content {
  background: #fff;
  width: 90vw;
  max-width: 620px;
  padding: 3rem;
  border-radius: 0.25rem;
  text-align: center;
  position: relative;
}
.modal-content p {
  font-size: 1.5rem;
  text-transform: none;
}
.close-btn {
  margin-right: auto;
}
